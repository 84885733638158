import React from "react";

import Header from "../../sections/Header";
import Footer from "../../sections/Footer";

const Terms = () => {
    return (
        <div className="app-info">
            <Header isFilled={true}/>

            <div className="container">
                <div className="app-info-content">
                    <h2>TelecomsXChange Subscribers Agreement</h2>
                    <small>This subscription agreement was last updated 23:00 (GMT) on December 10th, 2019</small>
                    <p>TelecomXChange LLC (TCXC) provides wholesale VoIP , SMS, DID services to customers in
                        accordance with the terms and conditions set forth in this agreement.</p>
                    <p>By registering for, activating, making use of, or by paying for TCXC site or services you
                        acknowledge having read and agreed to these terms, and you represent that there is no legal
                        impediment to your entering or being bound by this agreement.</p>
                    <h2>Subscription Fees; Charges; Taxes</h2>
                    <p>TCXC annual subscription gives communications service providers (CSPs) online access to
                        discover, view and interconnect with CSPs (sellers) in minutes using a single wallet via User
                        Interface or application programming interface (API), For more details on what the subscription
                        covers, please visit <a href="https://www.telecomsxchange.com">https://www.telecomsxchange.com</a>.</p>

                    <p>Subscription fees and any other charges for the use of the Application are described on the Site.
                        They may change from time to time. If subscription fees change, we will give you at least 30
                        days’ notice. If they do change, your continued use of the Application after the change indicates
                        your agreement with the new fees and charges after the effective date of the change. Any
                        change to fees and other charges will not be applicable to the billing period in which the change
                        occurs. Fees for payment transactions are subject to change without notice, unless otherwise
                        prohibited, at the discretion of our payment platform partners. Current pricing can be <a href="https://www.telecomsxchange.com/pricing">found here</a>.</p>
                    <p>The subscription fee can be paid online using a corporate debit or credit card or via Bank Wire
                        transfer. Payment information is attached to the membership invoice that you have already
                        received via email.</p>

                    <p>Failure to renew your subscription annual fee on time, Access to TCXC service will be
                        automatically disabled, you may keep a credit or debit card on file for auto-renewals.</p>

                    <h2>Voice Termination Billing</h2>
                    <p>Please refer to TCXC Wholesale prepaid agreement, provided separately .</p>
                    <h2>Online Portal Credit Card Top-up</h2>
                    <p>Transaction charges incurred by TelecomsXChange will be passed on to the customer at the
                        rates and percentages indicated when the customer adds credit to their account. The customer
                        fully accepts these charges when they elect to "Top Up" on our <a href="https://members.telecomsxchange.com/">members portal</a>.</p>

                    <p>TelecomsXChange reserve the right to change the rate of online payment charges at anytime,
                        notice is deemed to have been given when the new charges appear on the website.</p>

                    <h2>Taxes and USF Charges</h2>

                    <p>If any local, state, federal, public or quasi-public governmental entity or its political subdivision
                        imposes any taxes, fees, surcharges, or other charges or obligations on TelecomsXChange as
                        a result of TelecomsXChange’s sale of Services or Customer’s use of the Services, Customer
                        shall pay any such obligations (Additional Charges) and indemnify TelecomsXChange from any
                        liability or expense associated with the Additional Charges. If Additional Charges are
                        assessable to support the federal Universal Service Fund (USF), TelecomsXChange may elect
                        to calculate and charge Customer in accordance with applicable regulations of the government
                        authority having jurisdiction (USF Charges).</p>

                    <h2>Subscriber: Unreasonable Rate Responsibility</h2>

                    <p>When you detect an unreasonable pricing that might bring huge losses to a seller you should
                        follow the below actions,</p>

                    <ul>
                        <li>Report the issue to seller</li>
                        <li>Report the issue to TelecomsXChange</li>
                        <li>Get a confirmation from seller before sending traffic</li>
                    </ul>

                    <h2>Short Duration Surcharge for USA & Canada Termination</h2>

                    <p>If Customer’s total Short Duration Calls (defined as all calls with a duration of 6 seconds or less)
                        is more than 10% (ten percent) of total calls during a monthly or other billing period shall be
                        assessed a Short Duration Surcharge of $0.02 per call for all Short Duration calls unless
                        otherwise permitted by the Seller on TelecomsXChange.</p>

                    <h2>Measuring Usage</h2>
                    <p>You agree that TelecomsXChange’s records are conclusive evidence of your use of the site and
                        service. Furthermore, you acknowledge that these records will be used to calculate charges
                        incurred.</p>

                    <p>You accept that any use of the service originating from the IP addresses you nominate shall be
                        deemed to be authorised, and that you will pay any charges arising from such use.</p>
                    
                    <p>TelecomsXChange will notify authorized account users via email and SMS when a new
                        origination IP-Address is added online for your account.</p>

                    <p>Any request for a billing adjustment must be made in good faith within thirty (30) days of invoice
                        receipt through a Written Dispute Notification.</p>

                    <p>For the purpose of this Agreement, a Written Dispute Notification means an email message that
                        must include the following information: </p>

                    <p>Disputed destinations (route description)
                        Amount of total dispute
                        Dispute type (e.g. rate, minute, or code)
                        If rate dispute, the claimed rates for each disputed destination
                        If minute dispute, the claimed minutes and associated call detail records for each disputed
                        destination</p>

                    <p>If code dispute, the claimed codes and associated rates for each disputed destination</p>

                    <h2>E911 (Enhanced 911)</h2>

                    <p>By using our services you acknowledge that TelecomsXChange does not offer E911 services as
                        part of any existing product or service. Furthermore, TelecomsXChange does not offer
                        termination through its rate decks to 911 emergency services phone numbers.
                        TelecomsXChange do not hold any information that would help identify you or your users to
                        emergency services.</p>

                    <p>Your use of TelecomsXChange products and/or services represents your agreement that you
                        have alternative E911 services in place for you and your users.</p>
                    
                    <h2>Your Responsibilities</h2>

                    <p>You agree to be held fully responsible for all use of and fees related to your account, and accept
                        full liability and responsibility for the actions of anyone who uses the site or service via your
                        account, whether authorised or unauthorised, except when unauthorised use is solely attributed
                        to an act or omission on the part of TelecomsXChange.</p>

                    <h2>Regulatory Issues</h2>

                    <p>TelecomXChange LLC. makes no representations or warranties that the FCC, PUCs, any type
                        of government body, whether federal, state (provincial) or local, or any underlying providers will
                        not make any changes to existing laws and/or tariffs that could impair TelecomsXChange's
                        ability to deliver service to Customer.</p>

                    <h2>Liability & Indemnification</h2>

                    <p>You agree to indemnify TelecomsXChange and its agents, and hold them harmless from any
                        claims or damages relating to this agreement. You agree not to hold TelecomsXChange or its
                        agents liable for any loss of business, contracts, savings or profits, or any other damages or
                        losses (direct or consequential) to the extent that TelecomsXChange’s liability can be limited by
                        law.</p>

                    <h2>Term of and Changes to This Agreement</h2>

                    <p>The terms of this agreement shall come into effect immediately upon appearing on the TCXC
                        website, as indicated by the time-stamp at the header of this document. TCXC may terminate
                        this agreement at any time for any breach of these terms or any applicable policy as posted on
                        the TCXC website. No reimbursement for any fees charged in connection with TCXC services
                        shall be issued for such a termination.</p>

                    <p>Any provision of this agreement relating to term, payment, liability, indemnification, governing
                        law, or confidentiality shall survive termination or expiration.</p>
                    <p>Should a provision of this agreement be found to be invalid or unenforceable, or should
                        TelecomsXChange choose to waive any provision contained herein, you agree that the
                        remainder of the agreement remains unaffected and in full force.</p>

                    <h2>DID Numbers Subscriptions</h2>

                    <p>Ordering a DID or DID services on TelecomsXChange website constitutes an authorized
                        purchase order, and the funds will be removed from the user's account as soon as the order is
                        filled. Monthly recurring charges are billed on the same purchase day of each month. This is
                        also called the DID billing cycle. DIDs and DID services are prepaid one month in advance.</p>

                    <p>Setup fees are also called "NRC" (non-recurring charge).
                        Monthly Charges are called "MRC" (monthly recurring charges)</p>

                    <p>DID Setup costs include the DID monthly cost for the remainder of the current DID billing cycle.
                        (Example: if you buy a New York City DID on April 10th then you will be charged a $4.00 Setup
                        fee on April 10th. The next fee you will be charged will be for the beginning of the next billing
                        cycle starting on April 25th.)
                        Cancelling a DID or DID services in the middle of a DID cycle will not be eligible for any full or
                        partial refund.</p>

                    <p>DIDs will continue to be charged until a proper cancelation of the number(s) online or via API is
                        performed.</p>
                    
                    <p>Any problems or service issues with DID service must be reported immediately to the DID seller
                        through the online portal or via API or Email <a href="mailto:support@telecomsxchange.com">support@telecomsxchange.com</a> and will be
                        evaluated within 24 hours.</p>
                
                    <h2>Disclaimer:</h2>

                    <p>The information below may be adjusted from time to time, so it is highly recommended to
                        consult this page (<a href="https://www.telecomsxchange.com/terms">https://www.telecomsxchange.com/terms</a> ) on a regular basis, any important
                        amendments to the subscription agreement will include a special notice sent to members by
                        email or regular mail with 30 days notice.</p>

                    <p>The information is only intended to give customers some guidance with regard to the applicable
                        regulatory framework.</p>
                    
                    <p>The information has no legal standing and is not intended as a substitute for legal or regulatory
                        advice.</p>

                    <p>Any customer is solely responsible for the operation of its own business, for complying with any
                        applicable legal and regulatory provisions and for applying and obtaining the required license(s)</p>

                    <p>TelecomsXChange recommends its customers to seek advice on any legal and regulatory
                        obligations and restrictions before using the service</p>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Terms;