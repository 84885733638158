import React from "react";
import { useScreen, mediaType } from "../../../helpers";
import Title from "../../../components/Title";

const showMedia = (media, poster) => {
    if (mediaType(media) == 'image') {
        return (
            <div className="media__wrap">
                <img src={media} alt="" />
            </div>
        );
    }
    else if (mediaType(media) == 'video') {
        return (
            <div className="media__wrap"
                dangerouslySetInnerHTML={{
                    __html: `
                        <video autoplay="autoplay" loop playsinline muted ${poster ? `poster="${poster}"` : ''}>
                            <source src="${media}" type="video/mp4"/>
                            Your browser doesn't support video playing
                        </video>
                    `
                }}
            ></div> 
        );
    }
}

const Media = ({
    title,
    icon,
    media
}) => {
    const screen = useScreen();
    
    return (
        <section className="app-section media">
            <div className="container">
                { title?.length ? <Title icon={icon ?? null}>{title}</Title> : ''}
                            
                {(() => {
                    if (media.desktop) {
                        if (!media.mobile || (screen !== 'xs' && screen !== 'sm')) {                                
                            return showMedia(media.desktop, media.poster ?? null);
                        }
                    }
                    
                    if (media.mobile) {
                        if (!media.desktop || (screen == 'xs' || screen == 'sm')) {
                            return showMedia(media.mobile, media.poster ?? null);
                        }
                    }
                })()}
            </div>
        </section>
    );
}

export default Media;