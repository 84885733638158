import React from "react";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import Button from "../../components/Button";
import { 
    DataTransformed, 
    TopKeyfeatures, 
    Media,
    BlackRounded,
    FAQ,
    DiveInto 
} from "../../sections/TeleAnalytix";

import { 
    BckStarsDark, 
    TeleAnalytixAILogo, 
    DownloadIconWhite, 
    InterfaceInCircle, 
    InteractiveChatbotInterfaceMobile, 
    InteractiveChatbotInterfaceDesktop,
    CodeInCircle, 
    ApiIntegrationLogo, 
    ApiIntegrationVideo,
    AIPoweredInsightsVideo,
    AIPoweredInsightsPoster,
    ApiIntegrationPoster,
    StarsInCircle
} from "../../helpers/images";

const TeleAnalytixAI = () => {
    return (
        <div className="app-layout app-teleanalytixai">
            <Header/>
            
            <main className="app-main">
                <div className="main-hero">
                    <div className="main-hero-bcg">
                        <img src={BckStarsDark} alt="" />
                    </div>
                    
                    <div className="container">
                        <div className="main-hero-wrap">
                            <div className="main-hero-content">
                                <h1>Empowering Telecom Insights
                                    with <img src={TeleAnalytixAILogo} alt="" />
                                </h1>
                                <p>Revolutionary cutting-edge project powered by AI</p>
                                
                                <Button 
                                    type='link'
                                    link='/cloud' 
                                    className={'purple-gradient'}
                                    text={'White-Paper'}
                                    icon={DownloadIconWhite} />                 
                            </div>
                        </div>
                    </div>
                </div>

                <DataTransformed/>   
                <TopKeyfeatures/>             
                
                {/* Interactive Chatbot Interface */}
                <Media 
                    title={'Interactive Chatbot Interface'} 
                    icon={InterfaceInCircle}
                    media={{
                        desktop: InteractiveChatbotInterfaceDesktop,
                        mobile: InteractiveChatbotInterfaceMobile 
                    }} />
                
                {/* TelecomsXChange API Integration */}
                <Media 
                    title={ApiIntegrationLogo} 
                    icon={CodeInCircle} 
                    media={{
                        desktop: ApiIntegrationVideo,
                        poster: ApiIntegrationPoster                        
                    }}/>

                {/* AI-Powered Insights */}
                <Media 
                    title={'AI-Powered Insights'} 
                    icon={StarsInCircle} 
                    media={{
                        desktop: AIPoweredInsightsVideo,
                        poster: AIPoweredInsightsPoster
                    }}/>
                
                <BlackRounded/>
                <FAQ/>
                <DiveInto/>
            </main>
            
            <Footer/>
        </div>
    );
}

export default TeleAnalytixAI;