import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

// images
import CodeImg from "../../assets/images/platform-hero.png";
import CodeImgSecond from "../../assets/images/code-editor.png";
import arrRight from "../../assets/icons/arrow-right.svg";
import dragCircle from "../../assets/icons/drag-circle.svg";

import company_one from "../../assets/images/companies/1.png";
import company_two from "../../assets/images/companies/2.png";
import company_three from "../../assets/images/companies/3.png";
import company_four from "../../assets/images/companies/4.png";
import company_five from "../../assets/images/companies/5.png";

import cloudImg from "../../assets/images/cloud-home.png";
import platformImgOne from "../../assets/images/platform-1.png";
import platformImgSecond from "../../assets/images/platform-2.png";

import categoryImg from "../../assets/icons/category.svg";
import searchImg from "../../assets/icons/search.svg";
import walletImg from "../../assets/icons/wallet.svg";

// components
import Tag from "../../components/Tag";
import Button from "../../components/Button";
import Header from "../../sections/Header";
import Footer from "../../sections/Footer";
import List from "../../components/List";
import HomeSlider from "../../components/HomeSlider";
import Newsletter from "../../sections/Newsletter";
import WhitePapper from '../../sections/WhitePapper';

// sections

import Integrations from '../../sections/Integrations';

const Home = () => {

    const companies = [company_one, company_two, company_three, company_four, company_five]

    const [isAnimattedDrag, setIsAnimatedDrag] = useState(true);
    const [dragValue, setDragValue] = useState(500);
    
    const dragChange = (e) => {
        setDragValue((e.target.value));
        setIsAnimatedDrag(false);
    }

    useEffect(() => {
        if (isAnimattedDrag == false) {
            let animatedTimer = setTimeout(() => {
              setIsAnimatedDrag(true);
            }, 700);

            return () => clearTimeout(animatedTimer);
        }
    }, [dragValue])

    return (
        <div className="app-home">
            <Header/>
        
            <div className="main-hero">
                <div className="container">
                    <div className='main-hero-wrap circle'>
                        <div className="main-hero-content">
                            <h1>Building the <span>future of</span> telecom wholesale</h1>
                            
                            <p>Accelerating CSPs' digital transformation with full stack software-defined OPEN wholesale platform.</p>
                            
                            <Link to="/cloud" className='main-hero-content-link'>
                                <div className="main-hero-content-link-arrow">
                                    <img src={arrRight} alt="" />
                                </div>

                                Get Started
                            </Link>
                        </div>

                        <div className="main-hero-img circle">
                            <div className="app-home-hero-img-wrap">
                                <img className="app-home-hero-img" src={CodeImg} alt="" />
                            </div>
                            <div className="app-home-hero-img-wrap second" style={{'width': (100 - (dragValue / 10)) + '%'}}>
                                <img className="app-home-hero-img" src={CodeImgSecond} alt="" />
                            </div>
                            <input 
                                type="range" 
                                min="1" 
                                max="1000" 
                                value={dragValue} 
                                onChange={dragChange}
                                className="app-home-hero-drag" 
                                name='app-home-hero-drag' id="app-home-hero-drag"></input>
                            
                            <div 
                                style={{'left': (dragValue / 10) + '%'}}
                                className={`app-home-hero-drag-line`}>

                                <span className={isAnimattedDrag ? 'animated': ''}>
                                    <img src={dragCircle} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="app-home-hero-companies">
                        <h3>Featured Service Providers </h3>
                        <div className="app-home-hero-companies-wrap">
                            {companies.map((item, index) => (
                                <div className="app-home-hero-companies-item" key={index}><img src={item} alt="" /></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

           <section className="app-home-cloud">
                <div className="container">
                    <div className="app-home-description">
                        <Tag text="TCXC SaaS"/>

                        <h2>TCXC SaaS - <span>cloud exchange</span> service</h2>
                        <p>Cloud based exchange platform for voice, SMS 
                        and numbers with Choose Your Own Carrier (CYOC) technology and programmable API for developers.</p>
                        <Button type={'link'} link='/cloud'/>
                    </div>
                </div>

                <div className="container">
                    <div className="app-home-cloud-img">
                        <img src={cloudImg} alt="" />
                    </div>

                    <List children={[
                        {
                            img: {
                                icon: categoryImg,
                                color: '#542FBE'
                            },
                            title: 'Enhanced Dashboard',
                            text: 'Our buyers and sellers have access to trading tools that combine  user friendly with technicalle advancement.'
                        },
                        {
                            img: {
                                icon: searchImg,
                                color: '#262D3A'
                            },
                            title: 'Market View Search',
                            text: 'We have focued on providing our members with the ideal method to transaction. Our buyers are able to load money into the system throught credit cards, PayPal, Western Union and bank transfers.'
                        },
                        {
                            img: {
                                icon: walletImg,
                                color: '#DBFA60'
                            },
                            title: 'FastPay Fund Transfers ',
                            text: 'We have focued on providing our members with the ideal method to transaction. Our buyers are able to load money into the system throught credit cards, PayPal, Western Union and bank transfers.'
                        }
                    ]} />
                </div>
            </section>           

            <section className="app-home-platform">
                <div className="container">
                    <div className="app-home-description">
                        <Tag text="TCXC Platform"/>

                        <h2>TCXC <span>Platform</span> as a Service (PaaS)</h2>
                        <p>Fully Featured Software platform that enables telecom operators 
                            to complete their wholesale business digital 
                            transformation and go to market quickly
                        </p>
                        <Button link="/platform"/>
                    </div>
                </div>
                <div className="container">
                    <div className="app-home-platform-items">
                        <div className="app-home-platform-item">
                            <div className="app-home-platform-item-text">
                                <h4>Reimagine your product with forward-thinking solutions</h4>
                                <p>Empower your partners by joining your Digital Exchange to buy and sell voice, SMS , Phone numbers.</p>
                            </div>
                            <div className="app-home-platform-item-img">
                                <img src={platformImgOne} alt="" />
                            </div>
                        </div>
                        
                        <div className="app-home-platform-item">
                            <div className="app-home-platform-item-text">
                                <h4>TelecomsXChange (TCXC) Platform as a Service</h4>
                                <p>With TelecomsXChange (TCXC) Platform-as-a-Service, Carriers can complete digital transformation with ease. TCXC Paas enables global carriers to automate and digitize their VoIP, SMS, Numbers and more...</p>
                            </div>
                            <div className="app-home-platform-item-img mt-40">
                                <img src={platformImgSecond} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
            
            
            <HomeSlider/>
            
            <Newsletter/>
    
            <Footer/>
        </div>
    )
}

export default Home;