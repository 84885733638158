import React from 'react';

// images
import HeroImg from "../../assets/images/platform-hero.png";
import HeroBcgOne from "../../assets/images/platform-hero-2.svg";
import HeroBcgSecond from "../../assets/images/platform-hero-3.svg";

import overviewOne from "../../assets/images/overview-1.svg";
import overviewSecond from "../../assets/images/overview-2.png";
import overviewThird from "../../assets/images/overview-3.png";
import overviewFour from "../../assets/images/overview-4.png";

import blockImgOne from "../../assets/images/block-img-1.png";
import virtualExchangeImg from "../../assets/images/virtual-exchange.png";
import programmingExchangeImg from "../../assets/images/programming-exchange.png";

import microIcon from "../../assets/icons/microphone.svg";
import messageIcon from "../../assets/icons/message.svg";
import reverseIcon from "../../assets/icons/reverse.svg";
import categoryIcon from "../../assets/icons/category-dark.svg";

// components
import Header from "../../sections/Header";
import Cards from "../../sections/Cards";
import Footer from "../../sections/Footer";
import List from '../../components/List';

// sections
import WhitePapper from '../../sections/WhitePapper';
import Integrations from '../../sections/Integrations';
import Cases from '../../sections/Cases';

const Platform = () => {
    return (
        <div className='app-platform'>
            <Header/>

            <div className="main-hero">

                <div className="main-hero-bcg one">
                    <img src={HeroBcgOne} alt="" />
                </div>

                <div className="main-hero-bcg second">
                    <img src={HeroBcgSecond} alt="" />
                </div>

                <div className="container">
                    <div className='main-hero-wrap circle'>
                        <div className="main-hero-content">
             
                            <h1>TCXC <span>Platform</span> as a Service</h1>
                            <p>Full-stack, software-defined solution that empowers telecom operators to quickly complete their wholesale business digital transformation (DX) and enter the market with agility.</p><br></br>
                            <hr></hr>
                          <p>Carrier Grade, OPEN Core. Deployable anywhere!</p>

                        

                        </div>

                        <div className="main-hero-img circle">
                            <img className="app-platform-hero-img" src={HeroImg} alt="" />
                        </div>
                        
                    </div>
                </div>
            </div>

           

            <WhitePapper/>

            <div className="app-platform-overview">
                <div className="container">
                
                    <h3>Platform Overview</h3>

                    <Cards data={[
                        {
                            title: 'Only Platform for full stack exchange Enablement',
                            text: 'Designed exclusively for the Communications Industry to deliver full stack digital exchange for true digital transformation',
                            img: overviewOne,
                            color: '#F4F5F7'
                        },
                        {
                            title: 'Intuitive Digital Portal',
                            text: 'Intuitive and Advanced market place portal for a complate buyer and seller self-servise',
                            img: overviewSecond,
                            color: '#F0EDF9'
                        },
                        {
                            title: 'Full Stack',
                            text: 'Voice (SIP), SMS (SMPP) with full support for DID(Virtual Numbers), Rates Automation and APls',
                            img: overviewThird,
                            color: 'rgba(219, 250, 96, 0.33)'
                        },
                        {
                            title: 'Fully Customizable',
                            text: 'ProgrammableX APls allows for countless application options based on the need for CSPs',
                            img: overviewFour,
                            color: '#F4F5F7'
                        }
                    ]}/>
                </div>
            </div>

            <div className="app-block voice">
                <div className="container">
                    <div className="app-block-img left">
                        <img src={blockImgOne} alt="" />
                    </div>

                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    color: '#DBFA60',
                                    icon: microIcon
                                },
                                title: 'Voice Exchange ',
                                text: 'Our buyers and sellers have access to trading tools that combine  user friendly with technical advancement.'
                            },
                            {
                                img: {
                                    color: '#262D3A',
                                    icon: messageIcon
                                },
                                title: 'SMS Exchange',
                                text: 'Reliable and proven SMPP stack with tight integration with every part of the marketplace'
                            },
                        ]}/>
                    </div>
                </div>
            </div>

            <div className="app-block exchange">
                <div className="container">
                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    icon: reverseIcon,
                                    color: '#542FBE'
                                },
                                title: 'Virtual Numbers Exchange',
                                text: 'Digitally transforms buying and selling of virtual numbers, tightly integrated with Voice and SMS exchange. '
                            }
                        ]} />
                    </div>
                    <div className="app-block-img right">
                        <img src={virtualExchangeImg} alt="" />
                    </div>
                </div>
            </div>
            
            <div className="app-block programming">
                <div className="container">
                    <div className="app-block-img left">
                        <img src={programmingExchangeImg} alt="" />
                    </div>
                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    icon: categoryIcon,
                                    color: '#F4F5F7'
                                },
                                title: 'Programmable Exchange',
                                text: 'Discover how you can offer your users with powerful API for your services. API enablement for CSPs voice, SMS, numbers network and business functions, buyers and sellers or internal organization departments can interact with the platform and automate most complex wholesale business processes with a few lines of code. '
                            }
                        ]} />
                    </div>
                </div>
            </div>

            <Integrations/>
            <Cases/>

            <Footer/>
        </div>
    )
}

export default Platform;