import React, { useEffect, useRef, useState } from "react";
import Typed from 'typed.js';

import Phone from "../../assets/images/phone-request-code.png";

const RequestAnimation = () => {
    const request = [
        `curl -X <span class="red">POST</span></br>
        -H "Content-Type: application/json"</br>
        -u <span class="green">"{USERNAME}:{PASSWORD}"</span></br>
        -d'{ </br>
            "to": <span class="green">"{PHONE_NUMBER}"</span>,</br>
            "template_name": <span class="green">{2FA}"</span>,</br>
            "OrgName": <span class="green">"Acme Bank"</span>,</br>
            "OTP": <span class="green">"56667"</span>,</br>
            "channel": <span class="green">"whatsapp"<span></br>
        }</br>
        <span class="red">"https://whatsapp.telecomsxchange.com/send"</span>`
    ];
    
    let typed_ref = useRef(null);
    let [active_block, set_active_block] = useState('editor');

     useEffect(() => {
        const typed = new Typed(typed_ref.current, {
            strings: request, // Strings to display
            startDelay: 10,
            typeSpeed: 5,
            backSpeed: 0,
            shuffle: false,
            backDelay: 0,
            fadeOut: true,
            loop: false,
            contentType: 'html',
            onComplete: () => {
                set_active_block('editor');
      
                
                setTimeout(() => {
                    set_active_block('phone');
              
                }, 400);
               
            } 
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, []); 



        
    
    

   return (
        <div className="request-animation">
            
            <div className={`request-animation__block request-animation-editor ${active_block == 'editor' ? 'active': ''}`}>
                <div className="request-animation-editor__wrap">
                    <div className="request-animation-editor__head">
                        <span className="request-animation-editor__head-dot"></span>
                        <span className="request-animation-editor__head-dot"></span>
                        <span className="request-animation-editor__head-dot"></span>
                    </div>
                    <div className="request-animation-editor__code">
                        <span ref={typed_ref}></span>
                    </div>
                </div>
            </div>

           <div className={`request-animation__block request-animation-phone ${active_block == 'phone' ? 'active': ''}`}>
                <img src={Phone} alt="" />
            </div> 
        </div>
    ); 
}

export default RequestAnimation;