import React from "react";
import { mediaType } from "../../helpers";

const Title = ({ icon, color = 'gray-800', children, level = '2' }) => {
    
    let TitleTag = mediaType(children) == 'image' ? 'div' : `h${level}`;
    
    return (
        <div className="app-title">
            { icon ?
                <div className="app-title__icon">
                    <img src={icon} alt="" />
                </div>
            : '' }

            <TitleTag className="app-title__text" style={{color}}>
                {
                    mediaType(children) == 'image' 
                    ? <img src={children} alt="Title Image"/> 
                    : children
                }
            </TitleTag>
        </div>
    ); 
}

export default Title;