import firebase from "firebase";

const firebaseConfig = {
    apiKey: process.env.REACT_API_FIREBASE_KEY,
    authDomain: process.env.REACT_API_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_API_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_API_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_API_FIREBASE_SENDER_ID,
    appId: process.env.REACT_API_FIREBASE_APP_ID,
    measurementId: process.env.REACT_API_FIREBASE_MEASUREMENT_ID
};
  
firebase.initializeApp(firebaseConfig);
// firebase.analytics.getAnalytics()
var storage = firebase.storage();
  
export default storage;