import React from 'react';
import Button from '../../components/Button';

// images
import downloadIcon from "../../assets/icons/download.svg";
import papperImg from "../../assets/images/white-papper.png";

import File from "../../assets/files/white-papper.pdf";

const WhitePapper = () => {

    return (
        <div className="container">
            <div className="app-white_papper">
                <div className="app-white_papper-content">
                <h1>Reimagine your product with <span>forward-thinking</span> solutions</h1><br></br>
                <hr></hr>
                <br></br>
                    <h2>White Paper</h2>
                    <p>A lot of industries have gone digital, but many carriers are still maintaining the status quo. Carriers still conduct business mostly manually, and that is inefficient in the digital age.</p>
                    <p>Fortunatelly, there is an opportunity for carriers to become digitized and subsequently increase the speed at which they transact. In turn, there is potential to increase revenues for both carriers and service providers.</p>
                    <Button type="href" link={File} className={''} icon={downloadIcon} text="Download PDF"/>
                </div>
                <div className="app-white_papper-img">
                    <img src={papperImg} alt="" />
                </div>
            </div>
        </div>
    );
}

export default WhitePapper;