import React, { useState, useRef, useEffect } from "react";
import PhoneInput from 'react-phone-input-2';

// import 'react-phone-input-2/lib/style.css'

// images
import chDown from "../../assets/icons/chevron-down-dark.svg";
import chDownLight from "../../assets/icons/chevron-down-light.svg";
import checkIcon from "../../assets/icons/check.svg";
import closeIcon from "../../assets/icons/close.svg";
import downloadIcon from "../../assets/icons/upload.svg";

const Input = ({ 
    title, 
    placeholder, 
    type,  
    name, 
    flagsWith,
    required,
    filetypes,
    country,
    fields,
    form, 
    value,
    setForm,

    setInvalids,
    invalids
 }) => {
    
    const [dropdownActive, setDropdownActive] = useState(false);
    let [phoneCountry, setPhoneCountry] = useState(country ? country.toUpperCase() : '');
    let phoneRef = useRef(null);

    useEffect(() => {
        if (phoneRef.current && !flagsWith) {
            phoneRef.current.dropdownContainerRef.firstChild.innerHTML = phoneCountry;
        }
    }, [phoneCountry])

    return (
        <div className={`app-input ${type ?? ''}`}>
            {title ? <span className="app-input-title">{title}</span> : null}
            
            <div className="app-input-wrap">

                {
                    type && type == 'radio' ?
                        <>
                            <div className="app-input-radios">
                                {
                                    fields.map((elem, index) => (
                                    <label key={index} className={`app-input-radios-item`}>
                                        <input 
                                            type="radio" 
                                            checked={elem == form[name]}
                                            onChange={(e) => {
                                                setForm({...form, [name]: e.target.value})
                                            }}
                                            value={elem}
                                        />

                                        <div className={`app-input-radios-item-checkbox ${elem == form[name] ? 'active': ''}`}>

                                            {elem == form[name] ? 
                                                <img src={checkIcon} /> 
                                                : null
                                            }
                                        </div>
                                        <p>{elem}</p>
                                    </label>
                                    ))
                                }
                            </div>
                        </>
                    : type && (type == 'text' || type == 'textarea' || type == 'email' || type == 'file') ?
                    <>
                        {
                            type === 'textarea' ?
                            <textarea 
                                type="text"
                                name={name}
                                onBlur={(e) => {
                                    if (!e.target.value.length) {
                                        e.target.parentElement.classList.add('required');
                                        if (!invalids.includes(name)) {
                                            setInvalids([...invalids, name]);
                                        }
                                    }
                                    else {
                                        e.target.parentElement.classList.remove('required')
                                        setInvalids(prev => prev.filter(el => el !== name));
                                    }
                                }}
                                value={value}
                                onChange={(e) => setForm({...form, [name]: e.target.value})}
                                placeholder={placeholder ?? ''}
                            ></textarea>

                            : type == 'file' ?
                            <>
                                <label className="app-input-wrap-file">
                                    <input 
                                        type="file" 
                                        accept={filetypes ? filetypes: 'image/*'}
                                        name={name}
                                        value={''}
                                        onChange={(e) => {
                                            setForm({...form, [name]: e.target.files[0]})
                                        }}
                                    />
                                    <img src={downloadIcon} alt="" />
                                    <p>Choose File</p>

                                </label>
                                {form[name] ?
                                    <div className="app-input-wrap-file-nav">
                                        <span>{form[name].name}</span> 

                                        <div className="app-input-wrap-file-nav-close"
                                            onClick={() => {
                                                setForm({...form, [name]: ''})
                                        }}>
                                            <img src={closeIcon} alt="" />  
                                        </div>
                                    </div>    
                                    : null
                                }
                            </>
                            
                            : <input 
                                type={type == 'email' ? type : "text"}
                                name={name}
                                onBlur={(e) => {
                                    if (!e.target.value.length) {
                                        e.target.parentElement.classList.add('required');
                                        if (!invalids.includes(name)) {
                                            setInvalids([...invalids, name]);
                                        }
                                    }
                                    else if (type == 'email' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                                        e.target.parentElement.classList.add('invalid');
                                        if (!invalids.includes(name)) {
                                            setInvalids([...invalids, name]);
                                        }
                                    }
                                    else {
                                        setInvalids(prev => prev.filter(el => el !== name));
                                        e.target.parentElement.classList.remove('invalid')
                                        e.target.parentElement.classList.remove('required')
                                    }
                                }}
                                value={value}
                                onChange={(e) => setForm({...form, [name]: e.target.value})}
                                placeholder={placeholder ?? ''}
                            />
                        }
                    </>
                    : type == 'phone' ? 
                    <PhoneInput 
                        className={`app-input-phone ${flagsWith ? 'with-flags': ''}`}
                        placeholder={placeholder ?? ''}
                        country={country}
                        ref={phoneRef}
                        // renderStringAsFlag={!flagsWith && phoneCountry}
                        value={form[name]}
                        onChange={(phone, data, event, formattedValue) => {
                            setPhoneCountry(data.countryCode.toUpperCase())
                            setForm({...form, [name]: phone, phone_country: data.countryCode.toUpperCase()})
                        }}
                    />

                    : type && type == 'dropdown' ?
                        <div className="app-input-dropdown">
                            <div className="app-input-dropdown-current" onClick={() => setDropdownActive(!dropdownActive)}>
                                <span>{form[name] ?? ''}</span>
                                <img src={chDownLight} className={`app-input-dropdown-current-chevron ${dropdownActive ? 'active' : ''}`} alt="" />
                            </div>
                            <ul className={`app-input-dropdown-list ${dropdownActive ? 'active' : ''}`}>
                                {
                                    fields.map(item => (
                                        <li onClick={e => {
                                            setForm({...form, [name]: item})
                                            setDropdownActive(false);
                                        }} name={name} key={item}>{item}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    : null
                }
            </div>
        </div>
    );
}

export default Input;