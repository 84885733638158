import React from 'react';

import CheckIcon from "../../assets/icons/check.svg";

const IncludesList = ({list}) => {
    return (
        <ul className="app-includes-list">
            {
                list.map(item => (
                    <li key={item}>
                        <div className="app-includes-list-check">
                            <img src={CheckIcon} alt="" />
                        </div>
                        <p>{item}</p>
                    </li>
                ))
            }
        </ul>
    )
}

export default IncludesList;