import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Platform from "./pages/Platform";
import Cloud from "./pages/Cloud";
import Contact from "./pages/Contact";
import SignUp from "./pages/SignUp";
import Pricing from "./pages/Pricing";
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Smpp from './pages/Smpp';
import IoT from './pages/IoT';
import WhatsApp from './pages/WhatsApp';
import TeleAnalytixAI from './pages/TeleAnalytixAI';

import Modal from "./components/Modal";

export const MainContext = createContext({});

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 992 ? false : true);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setCurrentWidth(window.innerWidth);
      window.innerWidth > 992 ? setIsMobile(false) : setIsMobile(true);
    });

    return window.removeEventListener('resize', {}, false)
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <MainContext.Provider value={{isMobile, currentWidth, setModal}}>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/platform" element={<Platform/>} />
        <Route path="/cloud" element={<Cloud/>} />
        <Route path="/contact" element={<Contact/>} />
        {/* <Route path="/sign-up" element={<SignUp/>} /> */}
        <Route path="/sign-up" element={<SignUp/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/privacy" element={<Privacy/>}/>  
        <Route path="/terms" element={<Terms/>}/>  
        <Route path="/smpp" element={<Smpp/>}/> 
        <Route path="/iot" element={<IoT/>}/>
        <Route path="/whatsapp" element={<WhatsApp/>}/>     
        <Route path="/teleanalytixai" element={<TeleAnalytixAI/>}/>     

        <Route path="*" element={<Navigate to={{pathname: '/'}}/>} />
      </Routes>

      <Modal className={!modal ? 'hidden': ''} type={modal.type} text={modal.text} />
    </MainContext.Provider>
  );
}

export default App;