import React, {useEffect, useState } from 'react';

import Input from '../../../components/Input';

const Fill = ({ 
    fields, 
    form, 
    setForm, 
    changeStep,
    NextButton,
    setFields
}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [invalidFields, setInvalidFields] = useState([]);

    useEffect(() => {
        let maps = (Object.keys(form).every(el => form[el] !== '' && form[el] !== null && form[el] !== false) && invalidFields.length == 0)
        setIsDisabled(!maps);
    }, [form, invalidFields]);

    return (
        <div className="app-signup-fill">
            <h3>Company Info</h3>
            <p>This information will be displayed publicly so be careful what you share. </p>

            <div className="app-signup-fill-enter company">
                {
                    Object.keys(fields.company_info).map((item, index) => (
                        <Input 
                            key={item}
                            type={fields.company_info[item].type}
                            name={item}
                            value={form[item]}
                            title={fields.company_info[item].title}
                            fields={fields.company_info[item].fields ?? false}
                            placeholder={fields.company_info[item].dropdown ?? ''}
                            dropdown={fields.company_info[item].dropdown ?? false}
                            form={form}
                            filetypes={"image/jpeg, image/png, image/svg, application/pdf"}
                            required={fields.company_info[item].required ?? false}
                            setForm={setForm}
                            setInvalids={setInvalidFields}
                            invalids={invalidFields}
                        />
                    ))
                }
            </div>
            
            <h3>Contact Info</h3>
            <p>This information will be displayed publicly so be careful what you share. </p>
            
            <div className="app-signup-fill-enter contact">
                {
                    Object.keys(fields.contact_info).map((item, index) => (
                        <Input 
                            key={item}
                            type={fields.contact_info[item].type}
                            name={item}
                            title={fields.contact_info[item].title}
                            fields={fields.contact_info[item].fields ?? false}
                            placeholder={fields.contact_info[item].placeholder ?? ''}
                            country={fields.contact_info[item].country ?? false}
                            required={fields.contact_info[item].required ?? false}
                            dropdown={fields.contact_info[item].dropdown ?? ''}
                            form={form}
                            value={form[item]}
                            setForm={setForm}

                            setInvalids={setInvalidFields}
                            invalids={invalidFields}
                        />
                    ))
                }
            </div>

            <div className="app-signup-wrap-buttons">
                <NextButton onClick={() => changeStep('next')} text={'Next'} disabled={isDisabled}/>
            </div>
        </div>
    );
}

export default Fill;