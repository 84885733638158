import React from 'react';

// images
import HeroBcgOne from "../../assets/images/platform-hero-2.svg";
import HeroBcgSecond from "../../assets/images/platform-hero-3.svg";

import cardOne from "../../assets/images/buyers-1.png";
import cardTwo from "../../assets/images/overview-1.svg";
import cardThree from "../../assets/images/buyers-2.png";
import cardFour from "../../assets/images/overview-4.png";
import cardFive from "../../assets/images/buyers-3.png";

import blockImgOne from "../../assets/images/cloud-1.svg";
import blockImgTwo from "../../assets/images/code-editor.png";
import blockImgThree from "../../assets/images/cloud-2.png";
import blockImgFour from "../../assets/images/cloud-3.png";

import blockImgFive from "../../assets/images/cloud-4.png";
import blockImgSix from "../../assets/images/cloud-5.png";
import blockImgSeven from "../../assets/images/cloud-6.png";
import blockImgEight from "../../assets/images/cloud-7.png";

import messageIcon from "../../assets/icons/message.svg"
import categoryIcon from "../../assets/icons/category-dark.svg"
import settingsIcon from "../../assets/icons/settings.svg"
import microIcon from "../../assets/icons/microphone.svg"
import reverseIcon from "../../assets/icons/reverse.svg"


// components
import Header from '../../sections/Header';
import Footer from '../../sections/Footer';
import Cards from '../../sections/Cards';
import Tag from '../../components/Tag';
import List from '../../components/List';

// sections
import Features from '../../sections/Features';
import Integrations from '../../sections/Integrations';

const Cloud = () => {
    return (
        <div className="app-cloud">
            <Header/>

            <div className="main-hero">

                <div className="main-hero-bcg one">
                    <img src={HeroBcgOne} alt="" />
                </div>
                <div className="main-hero-bcg second">
                    
                    <img src={HeroBcgSecond} alt="" />
                </div>

                <div className="container">
                    <div className='main-hero-wrap'>
                        <div className="main-hero-content">
                            <h1>TCXC SaaS  <span>Choose Your Own Carrier</span></h1>
                            <p>The platform that lets you use voice, SMS, and virtual numbers without having to pay a commission. You can pick the carrier you want and start using the services right away.</p>
                        </div>

                        <div className="app-cloud-hero-about">
                            <div className="app-cloud-hero-about-average">
                                <p>Average tier1 interconnect time:</p>

                                <div className="app-cloud-hero-about-block">
                                    <span>Months</span>
                                    <div className="app-cloud-hero-about-average-wrap">
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                        <div className="app-cloud-hero-about-average-circle"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="app-cloud-hero-about-minutes">
                                <p>With TelecomXChange</p>
                                
                                <div className="app-cloud-hero-about-block">
                                    <span>Minutes</span>

                                    <div className="app-cloud-hero-about-minutes-wrap">
                                        <div className="app-cloud-hero-about-minutes-x circle"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                        <div className="app-cloud-hero-about-minutes-x"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container app-cloud-buyers-wrap">
                <div className="app-cloud-buyers">
                    <Tag text="FEATURES"/>
                    <h2>For Buyers</h2>
                    
                    <Cards data={[
                        {
                            title: 'Find and buy voice route access from hundreds of carriers with voice exchange',
                            color: '#F4F5F7',
                            img: cardOne
                        },
                        {
                            title: 'Interconnect using SIP and SMPP interfaces',
                            color: '#F0EDF9',
                            img: cardTwo
                        },
                        {
                            title: 'Instantly search and purchase voice and SMS enabled  phone numbers globally with self-service DID Exchange ',
                            color: 'rgba(219, 250, 96, 0.33)',
                            img: cardThree
                        },
                        {
                            title: 'Instantly find and buy SMS routes with self-service SMS exchange ',
                            color: '#F4F5F7',
                            img: cardFour
                        },
                        {
                            title: 'Standard rate sheet template from all sellers',
                            color: '#F0EDF9',
                            img: cardFive
                        },
                    ]}/>
                </div>
            </div>

            <div className="app-block direct">
                <div className="container">
                    <div className="app-block-img left">
                        <img src={blockImgOne} alt="" />
                    </div>

                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    icon: messageIcon,
                                    color: '#262D3A'
                                },
                                title: 'Direct messaging between buyers and sellers',
                            }
                        ]} />
                    </div>
                </div>
            </div>

            <div className="app-block api">
                <div className="container">
                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    icon: categoryIcon,
                                    color: '#DBFA60'
                                },
                                title: 'Programmable API access to all exchange functions for developers including, rates, metrics, interconnect and trouble ticketing',
                            }
                        ]} />
                    </div>

                    <div className="app-block-img right">
                        <img src={blockImgTwo} alt="" />
                    </div>
                </div>
            </div>
          
            <div className="app-block routing">
                <div className="container">

                    <div className="app-block-img left">
                        <img src={blockImgThree} alt="" />
                    </div>
                    
                    <div className="app-block-text">
                        <List children={[
                            {
                                img: {
                                    icon: settingsIcon,
                                    color: '#F4F5F7'
                                },
                                title: 'CYOC for your voice and messaging needs.',
                                text: 'Choose Your Own Carrier (CYOC) is the right balance between Bring your own Carrier (BYOC) and vendor lockdown. This option is popular among enterprise developers that have their own CPaaS network.',
                               
                            }
                        ]} />
                    </div>
                </div>
            </div>

            <div className="container app-cloud-sellers-wrap">
                <div className="app-cloud-sellers">
                    <Tag text="FEATURES"/>
                    <h2>For Sellers </h2>

                    <div className="app-block sellers">
                        <div className="container">

                            <div className="app-block-img left">
                                <img src={blockImgFour} alt="" />
                            </div>
                            
                            <div className="app-block-text">
                                <List children={[
                                    {
                                        img: {
                                            icon: messageIcon,
                                            color: '#262D3A'
                                        },
                                        title: 'Interconnect using SIP and SMPP Interfaces ',
                                    },
                                    {
                                        img: {
                                            icon: microIcon,
                                            color: '#DBFA60'
                                        },
                                        title: 'Quickly sell voice routes with self-service & programmable voice exchange',
                                    },
                                    {
                                        img: {
                                            icon: reverseIcon,
                                            color: '#542FBE'
                                        },
                                        title: 'Quickly sell SMS routes with self-service & programmable SMS Exchange',
                                    },
                                ]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="app-cloud-cards-second">
                    <Cards data={[
                        {
                            title: 'Instant payouts: withdraw funds anytime to your bank, paypal or Visa card ',
                            img: blockImgFive,
                            color: '#F4F5F7'
                        },
                        {
                            title: 'Sell & API enable virtual numbers with self-service DID exchange',
                            img: blockImgSix,
                            color: '#F0EDF9'
                        },
                        {
                            title: 'Smart blocking tools at the edge for SIP and SMPP traffic  ',
                            img: blockImgSeven,
                            color: 'rgba(219, 250, 96, 0.33)'
                        },
                        {
                            title: 'Sender ID management per bind ',
                            img: blockImgEight,
                            color: '#F4F5F7'
                        },
                    ]}/>
                </div>
            </div>
            
            <Features />
            <Integrations/>
            <Footer/>
        </div>
    )
}

export default Cloud;  