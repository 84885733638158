import React from 'react';
import { Link } from 'react-router-dom';
import arrRight from "../../assets/icons/arrow-right.svg";

const Button = ({icon, text, link, className, type, onClick, disabled = false}) => {
    
    if (type && type == 'submit') {
        return (
            <button
                className={`app-button ${className ?? ''}`}
                onClick={onClick}
                disabled={disabled}
            >
                {text ? text : 'Submit'}
                
                {icon !== false ?
                    <div className="app-button-icon">
                        <img src={icon ?? arrRight} alt="" />
                    </div>
                : ''}
            </button>
        )
    }
    else if (type && type == 'href') {
        return (
            <a
                href={link}
                className={`app-button ${className ?? ''}`}
                onClick={onClick}
                disabled={disabled}
                target="_blank"
            >
                {text ? text : 'Submit'}
                
                {
                    icon !== false ?
                        <div className="app-button-icon">
                            <img src={icon ?? arrRight} alt="" />
                        </div>
                    : ''
                }
            </a>
        )
    }

    return (
        <Link 
            to={link ? link : ''}
            className={`app-button ${className ?? ''}`}
        >
            {text ? text: 'Learn more'}

            {
                icon !== false ?
                <div className="app-button-icon">
                    <img src={icon ?? arrRight} alt="" />
                </div>
            : ''}
        </Link>
    )
}

export default Button;