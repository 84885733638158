import React from 'react';

import Header from '../../sections/Header';
import Footer from '../../sections/Footer';
import SmallHero from '../../components/SmallHero';

import IncludesList from '../../components/IncludesList';
import AccountCard from '../../components/AccountCard';

const Pricing = () => {
    return (
        <div className="app-pricing">
            <Header />
                <SmallHero className={'circles'}>
                    <div className="small-hero-text">
                        <h1>TCXC Cloud Exchange Subscription Pricing</h1><br></br>
                         <p><strong>Commission-free</strong> access to hundreds of carriers selling & buying voice, SMS and numbers around the world.</p> 
                      {/* <p><span className='green'>Sorry, we are unable to accept new members at this moment.</span></p> */}
                        
                    </div>
                </SmallHero>

                <div className="app-pricing-wrap">
                    <div className="container">
                        <div className="app-pricing-benefits">
                            <h2>Standard Membership Benefits</h2>
                            <div className="app-pricing-included">
                                <span className="green">What’s included</span>
                            </div>

                            <div className="app-pricing-benefits-list">
                                <IncludesList list={[
                                    'Instant access to Tier1, Tier2, Tier3 Voice and SMS Carriers',
                                    '0% commission on seller voice and SMS tariffs.',
                                    'Access to most updated E.212 E.164 List',
                                    '200 channels limit per IP-Address',
                                    'Easy SMS Sender ID Registration Process',
                                    'Realtime CDRs, SDRs and Call Stats Backup',
                                    'Unlimited Interconnects with sellers'
                                ]}/>
                                <IncludesList list={[
                                    'Receive Daily E-mail for Calls Stats Per Prefix',
                                    'Save 60% on wire fees and bank charges',
                                    'Automatic Blocking Tools for Junk Traffic or Low Stats VoIP Traffic',
                                    '24x7 Access to Market View and Vendor Rates',
                                    'Single rate sheet format for all vendors (E.212 + E164)'
                                ]}/>

                                <IncludesList list={[
                                    'Vendors profile access (Ratings and Reviews) and contact information',
                                    'Transfer funds from seller to buyer wallet instantly',
                                    'Get wholesale VoIP, SMS rates with no traffic Commitments'
                                ]}/>
                            </div>

                        </div>
                        
                        <div className="app-pricing-tariffs">
                            <AccountCard 
                                cost={'$ 999'}
                                link={'https://telecomsxchange.formstack.com/forms/buyer_signup'}
                                //link={'/smpp'}
                                description={
                                    <>
                                        <h2>Buyer’s Account</h2>
                                        <p>Get access to the leading open communications platform. Become a buying member today!</p>
                                    </>
                                }
                            />
                            
                            <AccountCard 
                                cost={'$ 999'}
                                link={'https://telecomsxchange.formstack.com/forms/seller_signup'}
                                //link={'/smpp'}
                                
                               
                                description={
                                    <>
                                        <h2>Seller’s Account</h2>
                                        <p>Offer your voice, sms routes and numbers to members around the world instantly. Become a TCXC seller today!</p>

                                        <div className="app-pricing-included">
                                            <span className="green">What’s included</span>
                                        </div>

                                        <div className="app-pricing-tariffs-card-lists">
                                            <IncludesList list={[
                                                'Limited number of Routes to Sell',
                                                'Prepayment for qualified vendors',
                                                'Standard Support / SLA',
                                                'Buyers profile access (Ratings and Reviews) from all members',
                                                '24x7 Access to Market View and Vendor Rates'
                                            ]}/>

                                            <IncludesList list={[
                                                'Unlimited Interconnects',
                                                'Force Retail Traffic only to your routes',
                                                'Instant Payouts - Visa - Bank - PayPal',
                                                'Auto Price-list distubation to all buyers'
                                            ]}/>
                                        </div>
                                    </>
                                }
                            />

                            <AccountCard 
                                className="mt-72"
                                cost={'$ 12 000'}
                                link={'https://buy.stripe.com/5kA9CM34baSgeQgcMQ'}
                                description={
                                    <>
                                        <h2>Premium Membership</h2>
                                        <p>If you would like to both a buyer and seller, you may click here to sign up for a discounted rate.</p>

                                        <div className="app-pricing-tariffs-card-lists">
                                            <IncludesList list={[
                                                'Premium SLA',
                                                'All Features in standard membership',
                                                'Elite access to Private Exchange',
                                                'Instant Alert of hot deals (SMS)',
                                                'Priority SMS Sender ID Registration Processing',
                                                'Updated E.212 to E.164 List',
                                                'Company logo promotion in front page',
                                                'Dedicated Success Account manager',
                                                'Instant promotion across the platform',
                                                '1000+ channel call capacity'
                                            ]}/>

                                            <IncludesList list={[
                                                'Capacity & Rate Priority',
                                                'Unlimited Interconnects',
                                                'Premium SLA',
                                                'IM Support',
                                                'Phone Support',
                                                'Access to special tools',
                                                '1 Free Incoming wire fees/month',
                                                '1 Free outgoing wire/month',
                                                '24x7 Traffic and QoS monitoring'
                                            ]}/>
                                        </div>

                                        <div className="app-pricing-included">
                                            <span className="black">Sellers</span>
                                        </div>

                                        <div className={`app-pricing-tariffs-card-lists`}>
                                            <IncludesList list={[
                                                '24x7 Access to Market View and sellers rates',
                                                'Unlimited TPS for SMS, Voice routes you Sell',
                                                'Force Retail Traffic only to your routes',
                                                'Unlimited DID Exchange Access'
                                            ]}/>

                                            <IncludesList list={[
                                                'API enablement for DIDs sold',
                                                'Instant Payouts - Visa - Bank - PayPal - Buyer Account',
                                                'Auto Price-list distubation to all buyers',
                                                'Programmable API access'
                                            ]}/>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default Pricing;