import React from "react";
import Button from "../../../components/Button";
import TeleAnalytixChat, { IncomingMessage, OutgoingMessage } from "../../../components/TeleAnalytixChat";

import { useScreen } from "../../../helpers";

import {
    TeleAnalytixAILogoArrow
} from "../../../helpers/images";
import ArcherLine from "../../../assets/images/teleanalytix/dive-into-archer-line.svg";
import ArcherLineSm from "../../../assets/images/teleanalytix/dive-into-archer-line-sm.svg";

const DiveInto = () => {
    const screen = useScreen();
    
    return (
        <section className="app-section dive-into">
            <div className="container">
                <div className="dive-into__wrap">
                    <h2><span>Dive into</span> a smarter way 
                    <br/> of data analysis with <img src={TeleAnalytixAILogoArrow} alt="" /></h2>
                
                    <Button icon={false} link="/" text={'Get In touch'} className="green"></Button>
                    
                    <TeleAnalytixChat openedInput={true}>

                        <OutgoingMessage>
                            <div className="archer-line">
                                <img src={(screen == 'xs' || screen == 'sm') ? ArcherLineSm : ArcherLine} alt="" />
                            </div>

                            <p>
                                Analyse my international call quality.
                            </p>
                        </OutgoingMessage>
                        <IncomingMessage>
                            <p>
                                After evaluating your international call data, prioritizing direct routing to key destinations like the UK and Germany is recommended for a potential +10% increase in ASR and a 7% reduction in call quality issues.
                            </p>
                        </IncomingMessage>
                    </TeleAnalytixChat>
                </div>
            </div>
        </section>
    );
}

export default DiveInto;