import React from 'react';

// images
import HeroImg from "../../assets/images/platform-hero.png";
import HeroBcgOne from "../../assets/images/platform-hero-2.svg";
import HeroBcgSecond from "../../assets/images/platform-hero-3.svg";

import overviewOne from "../../assets/images/overview-1.svg";
import overviewSecond from "../../assets/images/overview-2.png";
import overviewThird from "../../assets/images/overview-3.png";
import overviewFour from "../../assets/images/overview-4.png";

import blockImgOne from "../../assets/images/block-img-1.png";
import virtualExchangeImg from "../../assets/images/virtual-exchange.png";
import programmingExchangeImg from "../../assets/images/programming-exchange.png";

import microIcon from "../../assets/icons/microphone.svg";
import messageIcon from "../../assets/icons/message.svg";
import reverseIcon from "../../assets/icons/reverse.svg";
import categoryIcon from "../../assets/icons/category-dark.svg";

// components
import Header from "../../sections/Header";
import Cards from "../../sections/Cards";
import Footer from "../../sections/Footer";
import List from '../../components/List';

// sections
import WhitePapper from '../../sections/WhitePapper';
import Integrations from '../../sections/Integrations';
import Cases from '../../sections/Cases';

const Platform = () => {
    return (
        <div className='app-platform'>
            <Header/>

            <div className="main-hero">

                <div className="main-hero-bcg one">
                    <img src={HeroBcgOne} alt="" />
                </div>

                <div className="main-hero-bcg second">
                    <img src={HeroBcgSecond} alt="" />
                </div>

                <div className="container">
                    <div className='main-hero-wrap circle'>
                        <div className="main-hero-content">
                            <h1><span>Sorry!</span></h1>
                            <p>Unfortunately, we are unable to accept new members at this moment. We recommend checking back at a later time to see if the situation has changed.</p>
                        </div>

                        <div className="main-hero-img circle">
                            <img className="app-platform-hero-img" src={HeroImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <WhitePapper/>

            <Footer/>
        </div>
    )
}

export default Platform;