import React from "react";
import Button from "../Button";
/*
const AccountCard = ({ description, cost, className, link, disabled }) => {
    return (
        <div className={`app-account-card ${className ?? ''}`}>
            <div className="app-account-card-description">
                {description}
            </div>

            <div className="app-account-card-cost">
                <div className="app-account-card-cost-text">
                    <p>Annually</p>
                    
                    <div className="app-account-card-cost-text-value">
                        <h3>{cost}</h3>
                        <span>Year</span>
                    </div>
                    <Button type="href" link={link} text="Sign up now" className="green without-arrow" disabled/>
                </div>
            </div>
        </div>
    )
}

export default AccountCard; */

const AccountCard = ({ description, cost, className, link, disabled }) => {
    return (
      <div className={`app-account-card ${className ?? ''}`}>
        <div className="app-account-card-description">
          {description}
        </div>
  
        <div className="app-account-card-cost">
          <div className="app-account-card-cost-text">
            <p>Annually</p>
  
            <div className="app-account-card-cost-text-value">
              <h3>{cost}</h3>
              <span>Year</span>
            </div>
            <Button
              type="href"
              link={link}
              text="Sign up now"
              className="green without-arrow"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default AccountCard;
  