import React from 'react';
import {Link} from "react-router-dom";
// images
import HeroImg from "../../assets/images/platform-hero.png";
import HeroBcgOne from "../../assets/images/platform-hero-2.svg";
import HeroBcgSecond from "../../assets/images/platform-hero-3.svg";
import arrRight from "../../assets/icons/arrow-right.svg";


import overviewOne from "../../assets/images/overview-1.svg";
import overviewSecond from "../../assets/images/overview-2.png";
import overviewThird from "../../assets/images/overview-3.png";
import overviewFour from "../../assets/images/overview-4.png";

import blockImgOne from "../../assets/images/block-img-1.png";
import virtualExchangeImg from "../../assets/images/virtual-exchange.png";
import programmingExchangeImg from "../../assets/images/programming-exchange.png";

import microIcon from "../../assets/icons/microphone.svg";
import messageIcon from "../../assets/icons/message.svg";
import reverseIcon from "../../assets/icons/reverse.svg";
import categoryIcon from "../../assets/icons/category-dark.svg";
import Tag from '../../components/Tag';



// components
import Header from "../../sections/Header";
import Cards from "../../sections/Cards";
import Footer from "../../sections/Footer";
import List from '../../components/List';

// sections
import WhitePapper from '../../sections/WhitePapper';
import Integrations from '../../sections/Integrations';
import Cases from '../../sections/Cases';
import RequestAnimation from '../../sections/RequestAnimation';

const Platform = () => {
    return (
        <div className='app-page app-page_whatsapp'>
            <Header/>

            <div className="main-hero">

                <div className="main-hero-bcg one">
                    <img src={HeroBcgOne} alt="" />
                </div>

                <div className="main-hero-bcg second">
                    <img src={HeroBcgSecond} alt="" />
                </div>

                <div className="container">
                    <div className='main-hero-wrap circle'>
                        <div className="main-hero-content">
                          <p className='app-tag' style={{ color: 'black' }}>Developers</p><br></br>
                            <h1>Send messages via Whatsapp in <span>minutes..</span></h1>
                            <p>Skip the Complex API Onboarding. Simplify your WhatsApp integration by using our <strong>pre-approved phone numbers and templates</strong>. </p>
                        
                                {/*<Link to="/contact" className='main-hero-content-link'>
                                    <div className="main-hero-content-link-arrow">
                                        <img src={arrRight} alt="" />
                                    </div>

                                    Get Started
                                </Link> */}

                            <a href="https://telecomsxchange.formstack.com/forms/whatsapp_notifications" className="main-hero-content-link">
                                <div className="main-hero-content-link-arrow">
                                    <img src={arrRight} alt="" />
                                </div>
                                Sign up
                            </a>
                        </div>

                        <div className="main-hero-img request-animation-wrap">
                            <RequestAnimation/>
                        </div>                     
                    </div>
                </div>
            </div>
            <div className="app-platform-overview">
            
            
                <div className="container">
                
               
                    

                <Tag text="Overview"/>
                    <Cards data={[
                        


                        {
                            title: '2FA OTPs',
                            text: 'As a developer, you have the advantage of skipping the complex API onboarding process and can instead send 2FA messages immediately. This allows you to save time and streamline the process of sending 2FA messages, making it easier and more efficient for you to ensure the security of your users accounts.',
                            signUpUrl: 'https://telecomsxchange.formstack.com/forms/whatsapp_notifications',
                            color: '#F4F5F7'

                            
                        },
                        {
                            title: 'Alerts & Notifications',
                            text: 'Notifications for events like new customer sign-ups, orders, invoice payments, and server downtime are crucial for keeping stakeholders informed and enabling timely action. They help businesses operate smoothly and improve the customer experience.',
                         
                            color: '#F0EDF9'
                        },
                        {
                            title: 'Pre-Appoved Numbers',
                            text: 'To start sending messages right away, you can use our pre-approved business numbers, or you can set up your own number within 24 hours. This gives you flexibility and control over your messaging strategy, allowing you to choose the option that best fits your needs. ',
                            
                            img: HeroBcgOne,
                            color: 'rgba(219, 250, 96, 0.33)'
                        },
                        {
                            title: 'Pre-appoved Templates',
                            text: 'Our pre-approved WhatsApp templates for common use cases provide a fast and efficient solution for a quick go-to-market strategy. You can easily create and send messages for scenarios such as account verification, appointment reminders, and order updates, saving time and resources.',
                          
                            color: 'rgba(219, 250, 96, 0.33)'
                        }
                    ]}/>
                </div>
            </div> 


            <Integrations/>
            <Footer/>
        </div>
    )
}

export default Platform;