import React from "react";
import { ArcherContainer, ArcherElement } from 'react-archer';

import { 
    TeleAnalytixDataTransformed, 
    TeleAnalytixDataTransformedMobile,
    ArrowStartPoint,
    ArrowEndPoint 
} from "../../../helpers/images";

import TeleAnalytixChat, { IncomingMessage, OutgoingMessage } from "../../../components/TeleAnalytixChat";
import { useScreen } from "../../../helpers";


const DataTransformed = () => {
    const screen_w = useScreen();
    
    let arrow_style = {         
        strokeDasharray: '8,10', 
        strokeColor: "#CBD5E1", 
        strokeWidth: 1,
        startMarker: false, 
        endMarker: false 
    };

    return (
        <section className="app-section telecomanalytix-data-transformed">                
            <ArcherContainer noCurves={true}>
                <div className="container">                    
                    <div className="app-section__wrap">

                        <TeleAnalytixChat openedInput={true} shadowTop={true}>                        
                            
                            {/* Messages  */}
                            <OutgoingMessage>  
                                {/* Archer first point */}
                                <ArcherElement 
                                    id="outgoing-archer-1"
                                    relations={[
                                        {
                                            targetId: 'outgoing-archer-2',
                                            targetAnchor: 'left',
                                            sourceAnchor: 'bottom',
                                            style: arrow_style,
                                        },
                                    ]}>
                                    <div className="outgoing-archer-point outgoing-archer-point_1">
                                        <img src={ArrowStartPoint} alt="" />
                                    </div>
                                </ArcherElement>
                                <p>Summarize my payout records.</p>  
                            </OutgoingMessage>                            

                            <IncomingMessage>
                                <p>The total sum of payouts is $280,902.58 USD, and the dataset contains 152 transactions from one vendor. </p>
                                <p>The majority of transactions are categorised as 'HLR Lookup,' and the most recent transaction occurred 8 months ago (since November 2021). </p>
                                <p>The transaction ID range spans from 8323 to 9590, suggesting that the number of transactions conducted by this vendor has been increasing exponentially over time.</p>
                            
                                {/* Archer Last point */}
                                <ArcherElement id={(screen_w == 'sm' || screen_w == 'xs') ? '' : "incoming-archer-3"}>
                                    <div className="incoming-archer-point incoming-archer-point_3">
                                        <img src={ArrowEndPoint} alt="" />
                                    </div>
                                </ArcherElement>
                            </IncomingMessage>                        
                        </TeleAnalytixChat>

                        {/* Archer second point */}
                        <ArcherElement 
                            id="outgoing-archer-2"
                            relations={[
                                {
                                    targetId: 'outgoing-archer-3',
                                    targetAnchor: (screen_w == 'sm' || screen_w == 'xs') ? 'right' : 'bottom',
                                    sourceAnchor: 'bottom',
                                    style: {...arrow_style, startMarker: false, endMarker: true},
                                },
                            ]}>
                            <span className="outgoing-archer-point outgoing-archer-point_2"></span>
                        </ArcherElement>

                        <h2>Telecom Data Transformed</h2>                        

                        <div className="telecomanalytix-data-transformed__process-img"> 
                            {/* Archer third point */}
                            <ArcherElement id="outgoing-archer-3">
                                <span className="outgoing-archer-point outgoing-archer-point_3"></span>
                            </ArcherElement>

                            <img src={(screen_w == 'sm' || screen_w == 'xs') ? TeleAnalytixDataTransformedMobile : TeleAnalytixDataTransformed} alt="" />
                        
                            <ArcherElement 
                                id={(screen_w == 'sm' || screen_w == 'xs') ? '' : "incoming-archer-2"}
                                relations={[
                                    {
                                        targetId: 'incoming-archer-3',
                                        targetAnchor: 'left',
                                        sourceAnchor: 'right',
                                        style: {...arrow_style, endMarker: true},
                                    },
                                ]}>
                                <span className="incoming-archer-point incoming-archer-point_2"></span>
                            </ArcherElement>

                            <ArcherElement 
                                id={(screen_w == 'sm' || screen_w == 'xs') ? '' : "incoming-archer-1"}
                                relations={[
                                    {
                                        targetId: 'incoming-archer-2',
                                        targetAnchor: 'top',
                                        sourceAnchor: 'bottom',
                                        style: arrow_style,
                                    },
                                ]}>
                                <span className="incoming-archer-point incoming-archer-point_1"></span>
                            </ArcherElement>
                        </div>

                        <div className="telecomanalytix-data-transformed__info">
                            <p>Tele<b>Analytix</b> revolutionizes telecom data analysis with cutting-edge generative AI. </p>
                            <p>Empowering telecom operators to gain valuable Insights for strategic decision-making.</p>
                        </div>
                    </div>
                </div>
            </ArcherContainer>
        </section>
    );
}

export default DataTransformed;