import React from 'react';

const Cards = ({ data }) => {
    return (
        <div className="app-cards">
            {data.map(item => (
                <div 
                    key={item.title}
                    className="app-cards-item" 
                    style={{backgroundColor: item.color ? item.color : '#F4F5F7'}}>
                    
                    <h4>{item.title}</h4>
                    {item.text ? <p>{item.text}</p> : null }
                    <div className="app-cards-item-img">
                        <img src={item.img} alt="" />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Cards;