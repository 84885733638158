import React, { useContext } from 'react';

import { MainContext } from '../../App';

// images
import lineDesktop from "../../assets/images/integrations-line-desktop.svg";
import lineMobile from "../../assets/images/integrations-line-mobile.svg";

import slackLogo from "../../assets/icons/logos/slack.svg";
import paypalLogo from "../../assets/icons/logos/paypal.svg";
import jambonzLogo from "../../assets/icons/logos/jambonz.svg";
import docusignLogo from "../../assets/icons/logos/docusign.svg";
import freshdeskLogo from "../../assets/icons/logos/freshdesk.svg";
import mailgunLogo from "../../assets/icons/logos/mailgun.svg";
import restcommLogo from "../../assets/icons/logos/restcomm.png";
import subspaceLogo from "../../assets/icons/logos/subspace.png";
import twilioLogo from "../../assets/icons/logos/twilio.svg";
import stripeLogo from "../../assets/icons/logos/stripe.svg";


const Integrations = () => {
    const {isMobile} = useContext(MainContext)

    return (
        <div className="app-integrations">
            <div className="container">
                <h3>Active Integrations</h3>

                <div className="app-integrations-wrap">
                    {
                        isMobile 
                        ?
                        <>
                            <div className="app-integrations-wrap-img mobile">
                                <img src={lineMobile} alt="" />
                            </div>    

                            <div className="app-integrations-wrap-logos">
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={mailgunLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={stripeLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={slackLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={freshdeskLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={paypalLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={subspaceLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={restcommLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={twilioLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={docusignLogo} alt="" />
                                </div>
                                <div className="app-integrations-wrap-logos-item">
                                    <img src={jambonzLogo} alt="" />
                                </div>
                            </div>
                        </>

                        :
                        <div className="app-integrations-wrap-img desktop">
                            <img src={lineDesktop} alt=""/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Integrations;