import React, { useId, useState } from "react";
import Title from "../../../components/Title";
import { FaqInCircle } from "../../../helpers/images";

const FAQ = () => {
    let data = [
        {
            question: 'Who are the AI providers behind TeleAnalytixAI?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'What benefits does TelecomsXChange API Integration offer?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'What is the pricing model for TeleAnalytixAI?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'What is the minimum monthly spend in the pricing model?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'How can TeleAnalytixAI benefit telecom operators?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'What types of telecom data sources can I analyze with TeleAnalytixAI?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'Can TeleAnalytixAI handle large volumes of telecom data?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'Can I customize TeleAnalytixAI to suit my specific telecom data analysis needs?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'How does TeleAnalytixAI ensure data security and privacy?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        },
        {
            question: 'How can I get started with TeleAnalytixAI and begin using its features?',
            answer: 'TeleAnalytixAI utilizes AI from leading providers such as OpenAI, xAI, and Bard to transform raw data into actionable insights.'
        }
    ];

    const id = useId();
    const [ currentlyOpened, setCurrentlyOpened ] = useState(0);

    return (
        <section className="app-section faq">
            <div className="container">
                <Title icon={FaqInCircle}>Frequently Asked Questions</Title>
            
                <div className="faq__items">
                {
                    data.map((item, index) => (
                        <div 
                            className={`faq__item ${currentlyOpened === index ? 'faq__item_collapsed' : ''}`} 
                            key={`${id}-${index}`}
                            onClick={() => setCurrentlyOpened((prev) => prev !== index ? index : false )}>

                            <div className="faq__item-question">
                                <h4>{item.question}</h4>
                            </div>
                            <div className="faq__item-answer">
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </section>
    );
}

export default FAQ;