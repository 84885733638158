import React from "react";

const SmallHero = ({ className, children }) => {
    return (
        <div className={`small-hero ${className}`}>
            <div className="container">
                <div className="small-hero-wrap">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SmallHero;