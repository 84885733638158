import React, { useId } from "react";
import { 
    KeyfeaturesTitle,KeyfeaturesTitleSm,
    TopKeyfeaturesOne, TopKeyfeaturesTwo, TopKeyfeaturesThree, TopKeyfeaturesFour, TopKeyfeaturesFive, 
    InterfaceInCircleGray, CodeInCircleGray, StarsInCircleGray, ChartInCircleGray, PriceTagInCircleGray, 

} from "../../../helpers/images";
import { useScreen } from "../../../helpers";

import KeyfeaturesTwoArcher from "../../../assets/images/teleanalytix/keyfeatures-2-archer.svg";

const TopKeyfeatures = () => {
    const screen = useScreen();

    let data = [
        {
            content: {
                icon: InterfaceInCircleGray,
                title: 'Interactive Chatbot Interface',
                text: 'A conversation-based UI simplifying complex data analysis into engaging dialogues.'
            },
            img: TopKeyfeaturesOne
        },
        {
            content: {
                icon: CodeInCircleGray,
                title: 'TelecomsXChange API Integration',
                text: 'Seamless TCXC API integration, granting access to diverse telecom data sources: voice, SMS, IoT, and more.'
            },
            img: TopKeyfeaturesTwo,
            archer: KeyfeaturesTwoArcher
        },
        {
            content: {
                icon: StarsInCircleGray,
                title: 'AI-Powered Insights',
                text: 'Leveraging AI from OpenAI, xAI, and Bard to transform raw telecom data into meaningful insights.'
            },
            img: TopKeyfeaturesThree
        },
        {
            content: {
                icon: ChartInCircleGray,
                title: 'Predictive Analytics',
                text: 'Advanced machine learning algorithms for forecasting trends based on historical data, empowering proactive decisions.'
            },
            img: TopKeyfeaturesFour
        },
        {
            content: {
                icon: PriceTagInCircleGray,
                title: 'Flexible Pay-As-You-Go Pricing',
                text: 'Transparent and flexible pricing model where you only pay for what you use, with a minimum monthly spend to ensure consistent service delivery.'
            },
            img: TopKeyfeaturesFive
        },
    ];

    const id = useId();

    return (
        <section className="app-section top-keyfeatures">
            <div className="container">
                <div className="top-keyfeatures__title">
                    <img src={(screen == 'xs' || screen == 'sm') ? KeyfeaturesTitleSm : KeyfeaturesTitle} alt="" />
                </div>

                <div className="top-keyfeatures__items">
                    {
                        data.map((item, index) => {
                            return (
                                <div className={`top-keyfeatures__item top-keyfeatures__item_${index + 1}`} key={`${index}-${id}`}>
                                    <div className="top-keyfeatures__item-content">
                                        <div className="top-keyfeatures__item-icon">
                                            <img src={item.content.icon} alt="" />
                                        </div>

                                        <h3>{item.content.title}</h3>
                                        <p>{item.content.text}</p>
                                    </div>

                                    <div className="top-keyfeatures__item-img">
                                        <img src={item.img} alt="" />

                                        {item.archer ?
                                            <div className="top-keyfeatures__item-img-archer">
                                                <img src={item.archer} alt="" />
                                            </div>
                                            : ''
                                        }
                                    </div> 
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default TopKeyfeatures;