import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// images 
import categoryIcon from "../../assets/icons/category-dark.svg";
import chatIcon from "../../assets/icons/chat.svg";
import notifyIcon from "../../assets/icons/notification.svg";
import anchorIcon from "../../assets/icons/anchor.svg";
import settingsIcon from "../../assets/icons/settings.svg";

import slideOne from "../../assets/images/features-1.png";
import slideTwo from "../../assets/images/features-2.png";
import slideThree from "../../assets/images/features-3.png";

const Features = () => {
    const slides = [
        {
            icon: {
                img: categoryIcon,
                color: '#F4F5F7'
            },
            text: <span>API enable and automate wholesale selling operations with developer friendly <Link to="#">Programmable Exchange</Link></span>,
            img: slideOne,
            className: 't-60'
        },
        {
            icon: {
                img: anchorIcon,
                color: '#262D3A'
            },
            text: `Self-service capacity management and advanced routing policies per route/trunk/bind`,
        },
        {
            icon: {
                img: settingsIcon,
                color: '#DBFA60'
            },
            text: `Useful marketing tools`,
        },
        {
            icon: {
                img: notifyIcon,
                color: '#542FBE'
            },
            text: `Automated rate updates notification to all buyers on your behalf, upload once and we take care of the rest`,
        },
        {
            icon: {
                img: chatIcon,
                color: '#F4F5F7'
            },
            img: slideTwo,
            text: `Automated rate updates notification to all buyers on your behalf, upload once and we take care of the rest`,
            className: 't-60'
        },
        {
            icon: {
                img: chatIcon,
                color: '#F4F5F7'
            },
            img: slideThree,
            text: `Instant alerts when services are purchased `
        },
    ]

    const featuresSettings = {
        slidesToShow: 1,
        slidesToSscroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: false
    }

    return (
        <div className="container app-features-container">
            <div className="app-features">
                <div className="container app-features-wrap-container">
                    <h2>More features</h2>

                    <Slider {...featuresSettings} className="app-features-wrap">
                        {
                            slides.map((item, index) => (
                                <div className={`app-features-item`} key={index}>
                                    <div className="container">
                                        <div className="app-features-item-head">
                                            <div className="app-features-item-head-icon" style={{backgroundColor: item.icon.color}}>
                                                <img src={item.icon.img} alt="" />
                                            </div>

                                            <h4>{index + 1}/{slides.length}</h4>
                                        </div>
                                        <div className="app-features-item-content">
                                            <h4>{item.text}</h4>
                                            {
                                                item.img ?
                                                <div className={`app-features-item-content-img ${item.className ? item.className : ''}`}>
                                                    <img src={item.img} alt="" />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Features;