import React, { useState, useEffect } from 'react';

export const screens =  {
  'lg': 1200,
  'md': 992,
  'sm': 768,
  'xs': 576
};

// Create an array of sizes sorted in descending order
const sortedSizes = Object.keys(screens).sort((a, b) => screens[a] - screens[b]);

const useScreen = () => {
  const [screenSize, setScreenSize] = useState('');

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    
    for (const size of sortedSizes) {
      let sortedIndex = sortedSizes.indexOf(size);

      if (
        screenWidth < screens[size] && (!screenSize[sortedIndex + 1] || screenSize[sortedIndex + 1] > screenWidth) 
      ) {
        setScreenSize(size);
        return; // Exit the loop once a size is found        
      }
    }
    setScreenSize('lg');
  };

  useEffect(() => {
    handleResize(); // Set initial screen size
    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreen;