import React, { forwardRef } from "react";
import { 
    TeleAnalytixChatIconRounded,
    TeleAnalytixChatIcon, 
    TeleAnalytixAILogoNotColored,
    SendButtonIconGray 
} from "../../helpers/images";

import HeadNav from "../../assets/icons/teleanalytix/chat-head-nav.svg"
import ChatShadowTop from "../../assets/images/teleanalytix/chat-shadow-top.svg";

const TeleAnalytixChat = ({ children, openedInput, withCopyright, shadowTop }) => {
    return (
        <div className="teleanalytix-chat">
            {
                shadowTop ?
                <div className="teleanalytix-chat__shadow-top">
                    <img src={ChatShadowTop} alt="" />
                </div>
                : ''
            }

            <div className="teleanalytix-chat__head">
                <div className="teleanalytix-chat__head-nav">
                    <img src={HeadNav} alt="" />
                </div>

                <div className="teleanalytix-chat__head-logo">
                    <img src={TeleAnalytixChatIconRounded} alt="" />
                </div>

                <h6 className="teleanalytix-chat__head-title">TeleAnalytix</h6>
                <span className="teleanalytix-chat__head-status">Online</span>
            </div>

            <div className="teleanalytix-chat__main">
                {children}
            </div>

            <div className={`teleanalytix-chat__footer ${openedInput ? 'teleanalytix-chat__footer_filled' : ''}`}>
                {openedInput ?
                    <div className="teleanalytix-chat__footer-input">
                        <input type="text" readOnly placeholder={(typeof openedInput == 'boolean') ? 'Message' : null} value={(typeof openedInput == 'string' && openedInput.length) ? openedInput : ''} />
                        <img src={SendButtonIconGray} alt="" />
                    </div>
                : ''}

                {(withCopyright || openedInput) ?
                    <span className="teleanalytix-chat__footer-copyright">Powered by <img src={TeleAnalytixAILogoNotColored} alt="" /></span>
                : ''
                }
            </div>
        </div>
    );
} 

export const IncomingMessage = forwardRef(({children}, ref) => {
    return (
        <div ref={ref} className="teleanalytix-chat__message teleanalytix-chat__message_incoming">
            <div className="teleanalytix-chat__message-icon">
                <img src={TeleAnalytixChatIcon} alt="" />
            </div>

            <div className="teleanalytix-chat__message-text">
                {children}
            </div>
        </div>
    );
});

export const OutgoingMessage = forwardRef(({children}, ref) => {
    return (
        <div ref={ref} className="teleanalytix-chat__message teleanalytix-chat__message_outgoing">
            
            <div className="teleanalytix-chat__message-text">
                {children}
            </div>
        </div>
    );
});

export default TeleAnalytixChat;