import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

import axios from "axios";

import { newsLetterApi } from "../../config";
import { MainContext } from "../../App";

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [isDisabled, setIsDisabled] = useState(email.length && emailReg.test(email));

    const { setModal } = useContext(MainContext);

    useEffect(() => {
        setIsDisabled(email.length && emailReg.test(email));
    }, [email]);

    const submitEmail = async () => {
        try {
            setIsDisabled(true);
            let { data } = await axios.get(newsLetterApi, {
                params: {
                    email: email
                }
            });
    
            if (data.status == 'success') {
                setModal({type: 'success', text: 'Thanks. Your email successfully send!'})
            }
            setIsDisabled(false);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="container">
            <section className="app-newsletter">
                
                <div className="app-newsletter-bcg-circle one"></div>
                <div className="app-newsletter-bcg-circle two"></div>
                <div className="app-newsletter-bcg-circle three"></div>

                <div className="app-newsletter-wrap">
                    <h2>Join our Newsletter</h2>
                    <p>Stay up to date with the latest news on TCXC Cloud Exchange and TCXC Platform as a Service (PaaS).</p>

                    <div className="app-newsletter-form">
                        <input 
                            type="text" 
                            placeholder="Enter your email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Button type="submit" disabled={!isDisabled} onClick={submitEmail} text="Notify me" className={'green'}/>    
                    </div>

                    <span className="app-newsletter-wrap-privacy">We care about the protection of your data. Read our <Link to="/privacy">Privacy Policy.</Link></span>
                </div>
            </section>
        </div>
    );
}

export default Newsletter;